import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'

import 'MainComponet/css/custom.css'
const GetInTouch = () => {
 
  return (
    <>
     <section class="section-widget">
    <section class="container">
 <section class="getInTouch text-center " data-aos="fade-right"
 >
    <p className='font'>
If you have any questions, or you feel this may benefit your organisation; please reach out to us here.</p>
    <NavLink to="/contact" >
      <button class="btn btn-primary">Get in touch</button></NavLink>
    </section>
    </section>
</section>
    </>
  )
}

export default GetInTouch
