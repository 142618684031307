import axios from 'axios';

const doLogout = async () => {
    const token = localStorage.getItem("token");

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_PATH}/logout`, { token });
        return response;
    } catch (error) {
        console.log(error);
        throw error; 
    }
};

export default doLogout;

