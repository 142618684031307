import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Logo from "assets/images/small-logos/Logo-removebg-preview.png";
import "MainComponet/css/custom.css";

const Header = () => {
  
  return (
    <>
      <section className="circle circle1"></section>
      <section className="circle circle2"></section>
      <header id="header">
      
        <nav
          className="navbar navbar-expand-lg fixed-top navbar-light"
          style={{ backgroundColor: "#ffffff", boxShadow: "0 2px 4px rgba(0,0,0,0.1)" }}
        >
          <div className="container"  data-aos="fade-down">
            <NavLink to="/" className="navbar-brand">
              <img src={Logo} alt="The Connectar" height="300px" width="300px" />
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0 font">
                <li className="nav-item">
                  <NavLink
                    exact
                    activeClassName="active"
                    className="nav-link"
                    to="/why-the-connectar"
                   
                  >
                    Why The Connectar
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink activeClassName="active" className="nav-link" to="/why-you-should">
                    Data Insights
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink activeClassName="active" className="nav-link" to="/methodology">
                    Our Methodology
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink activeClassName="active" className="nav-link" to="/why-now">
                    {" "}
                    Why Act Now
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink activeClassName="active" className="nav-link" to="/contact">
                    Contact Us
                  </NavLink>
                </li>
              </ul>
              <div className="d-flex" role="search">
                <NavLink className="btn btn-outline-primary me-3" to="/login">
                  Login
                </NavLink>
              </div>
            </div>
          </div>
        </nav>
 
      </header>
    </>
  );
};

export default Header;
