import React, { useState } from "react";
import Box from "@mui/material/Box";
import { NavLink, useNavigate,  } from "react-router-dom";
import Logo from "assets/images/small-logos/Logo-removebg-preview.png";
import doLogout from "../helper.js";
import  Swal  from "sweetalert2";

function Nav({ data, isUserProfile, isPublicProfile }) {

  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

 

  const handleButtonClick = () => {
    setAnchorEl(null);

    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0d6efd",
      cancelButtonColor: "#d33",
      confirmButtonText: "Logout!",
    }).then(async(result) => {
      if (result.isConfirmed) {
        const response = await doLogout();
        if(response.data.status === 200 ){
          localStorage.removeItem('token');
          navigate('/')
        }
      }
    });
  };


  return (
   <>
   

<section className="circle circle1"></section>
      <section className="circle circle2"></section>
      <header id="header">
      <nav className="navbar navbar-expand-lg fixed-top navbar-light" style={{backgroundColor: '#ffffff', boxShadow: '0 2px 4px rgba(0,0,0,0.1)'}}>
  <div class="container">
  <NavLink to="/userprofile" className="navbar-brand" >
              <img src={Logo} alt="The Connectar" height="300px" width="300px" />
            </NavLink>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarText">
    <ul className="navbar-nav me-auto mb-2 mb-lg-0 font" {...(isUserProfile || !isPublicProfile ? {} : { style: { display: "none" } })}>
                <li className="nav-item" >
                  <NavLink exact activeClassName="active" className="nav-link" to="/userprofile" >Profile</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink activeClassName="active" className="nav-link" to="/connections" >Connections</NavLink>
                </li>
                <Box
            sx={{
              flexGrow: { xs: 1, md: 1, lg: 2, xl: 20 }, 
              display: { xs: "flex", md: "flex", lg:"flex" },
              justifyContent: "center",
            }}
          ></Box>
              </ul>
      <span class="navbar-text">
      <button  className="btn btn-outline-primary me-3" onClick={handleButtonClick} >Logout</button >
      </span>
    </div>
  </div>
</nav>
      </header>
   </>
  );
}
export default Nav;