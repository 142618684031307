import { useState, useEffect } from "react";

// react-router-dom components
import { Link, useNavigate, useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { useFormik } from "formik";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "assets/images/small-logos/image.png";
import { InputAdornment } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Swal from "sweetalert2/dist/sweetalert2";

import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function InviteLink() {
  const navigate = useNavigate();

  const { id } = useParams();

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmpassword: "",
    },
    onSubmit: (values) => {
      axios
        .post(`${process.env.REACT_APP_API_PATH}/changepassword/${id}`, values)
        .then((res) => {
          if (res.data.status == 201) {
            Swal.fire("Done!", " password changed successfully", "success");
            navigate("/login");
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    validate: (values) => {
      const errors = {};

      if (!values.password) errors.password = "Password is required";
      else if (
        !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/i.test(values.password)
      )
        errors.password =
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character";

      if (values.password !== values.confirmpassword && values.confirmpassword.length > 0) {
        errors.confirmpassword = "Passwords must match";
      }
      return errors;
    },
  });

  useEffect(() => {
    const auth = localStorage.getItem("token");
    if (auth) {
      navigate("/companies");
    }
  });
  const [password, setPassword] = useState("password");
  const [confirmpassword, setConfirmPassword] = useState("password");
  const [showIcon, setShowIcon] = useState(true);
  const [showForgetIcon, setShowForgotIcon] = useState(true);

  const handleShowPassword = () => {
    if (password === "password") {
      setPassword("text");
      setShowIcon(false);
      return;
    }

    setPassword("password");
    setShowIcon(true);
  };

  const handleShowConfirmPassword = () => {
    if (confirmpassword === "password") {
      setConfirmPassword("text");
      setShowForgotIcon(false);
      return;
    }

    setConfirmPassword("password");
    setShowForgotIcon(true);
  };

  return (
    <>
      {" "}
     
         <div className="border1"  
    >
        <div className=" container ">
          <form onSubmit={formik.handleSubmit} >
      <section class=" gradient-custom">
  <div class="container">
    <div class="row d-flex justify-content-center align-items-center">
            <h2 className="text-center mt-4"><span> Choose Your</span> Password</h2>
      <div class="col-12 col-md-8 col-lg-6 col-xl-5">
        <div class="card bg-white text-black" style={{ borderRadius: "1rem",boxShadow: '#0d6efd73 0px 2px 4px' }}>
          <div class="card-body p-5">
            <div class="mb-md-5">

            <div class="mb-3">
  <label for="password" class="form-label">Password</label>
  <div class="input-group">
    <input type={password}
                        id="password"
                        name="password"
                         className="form-control"   value={formik.values.password}
                         onChange={formik.handleChange}
                         onBlur={formik.handleBlur} required />
                          <button className="btn btn-outline-primary custom-btn" type="button" onClick={handleShowPassword}>
      {showIcon ? (
        <i className="fa fa-eye-slash" aria-hidden="true"></i>
      ) : (
        <i className="fa fa-eye" aria-hidden="true"></i>
      )}
    </button>
   
  </div>
</div>
<div style={{ color: "red", fontSize: "17px" }}>
                    {formik.touched.password && formik.errors.password ? (
                      <div>{formik.errors.password}</div>
                    ) : null}
                  </div>
              
              <div class="mb-3">
  <label for="password" class="form-label">Confirm Password</label>
  <div class="input-group">
    <input  type={confirmpassword}
                        id="confirmpassword"
                        name="confirmpassword"
                         className="form-control"   value={formik.values.confirmpassword}
                         onChange={formik.handleChange}
                         onBlur={formik.handleBlur} required />
                          <button className="btn btn-outline-primary custom-btn" type="button" onClick={handleShowConfirmPassword}>
      {showForgetIcon ? (
        <i className="fa fa-eye-slash" aria-hidden="true"></i>
      ) : (
        <i className="fa fa-eye" aria-hidden="true"></i>
      )}
    </button>
   
  </div>
</div>
<div style={{ color: "red", fontSize: "17px" }}>
                    {formik.touched.confirmpassword && formik.errors.confirmpassword ? (
                      <div>{formik.errors.confirmpassword}</div>
                    ) : null}
                  </div>

            
<div className=" text-center">

<button className="btn btn-primary d-block w-100" type="submit"  onSubmit={!formik.handleSubmit} >
Submit
                    </button>
                    
</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
</form>
      </div>
      </div>
    </>
  );
}

export default InviteLink;
