import React, { useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import GetInTouch from './GetInTouch'
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'MainComponet/css/custom.css'
import Remote from "../assets/images/FrontPage/Remote.png";
import Expectations from "../assets/images/FrontPage/Expectations.jpg";
import competitive from "../assets/images/FrontPage/competitive.jpg";
import LongTerm from "../assets/images/FrontPage/Long-Term.jpg"
import EmployeeHealth from "../assets/images/FrontPage/EmployeeHealth.jpg"
const WhyNow = () => {
  useEffect(() => {
    setTimeout(function() { 
      window.scrollTo(0, 0);
      AOS.init({ duration: 500, delay: 50 });
      AOS.refreshHard();
      setTimeout(function() {
        document.getElementById('footer');
        AOS.refreshHard();
      }, 1000);
    }, 100);
  }, []);

  return (
    <>
      <Header />
     
      {/* Tell us section */}
      <section class="border1">
        <section class="container">
          <section class="row align-items-center">
            <section class="col-sm-12 bannerContent" data-aos="fade-right">
              <h2>Why you should <span>do something now </span></h2>
              <section class="col-sm-12">
                <p class=" font">
                  For many years there has been an undercurrent of awareness
                  surrounding the importance of connection, ironically since the
                  adoption of smart technology and our ability to be anywhere else
                  in the world much more easily than ever before; we’ve never had
                  more issues with true connectedness, whereby we feel included and
                  accepted.
                </p>
                <div className="col-md-12 order-md-1" >
          <p className='font '> This of course is also occurring in the workplace, and if we’ve
                    learned anything from the pandemic, it’s that many functions
                    within organisations can be successfully conducted remotely and
                    those organisations that are embracing this change are leading
                    their competitors in the race to prove why quality employees want
                    to work for them rather than their competitors.  </p>
                    <div className="col-md-12 order-md-1" >
          <p className='font '>If your organisation has been wondering how you keep your staff connected regardless of where they work or which department they work in, The Connectar is perfect for you.  </p>
          </div>
          </div>
              </section>
            </section>
          </section>
        </section>
      </section>
    {/* baner text section */}

      <section className="container">
<section className="content-widget">
        <div className="row align-items-center "  data-aos="fade-right">
          <div className="col-md-5 text-center" 
 ><img src={Remote} alt=""  height="400px" width="300px" className="img-fluid" /></div>
          <div className="col-md-7" >
            <h2>Impact of  <span> Remote Work</span></h2>
            <p className='font '>With the rise of remote work, maintaining a sense of connection among employees has become even more challenging. Remote employees may feel isolated and disconnected from their colleagues, leading to decreased morale, engagement, and productivity. Addressing these challenges promptly is essential to ensuring the well-being and effectiveness of remote teams.</p>
          </div>
        </div>
        <div className="row align-items-center"  data-aos="fade-right">
          <div className="col-md-5 text-center order-md-2"  
 ><img src={Expectations} alt=""  height="400px" width="350px" className="img-fluid"/></div>
          <div className="col-md-7 order-md-1" >
          <h2>Changing Expectations  <span>of Employees</span></h2>
          <p className='font '>Employees today have different expectations when it comes to workplace culture and environment. They prioritize flexibility, work-life balance, and meaningful connections with their peers. Organizations that fail to meet these expectations risk losing top talent to competitors who offer more supportive and connected work environments.</p>
          </div>
        </div>
        <div className="row align-items-center "  data-aos="fade-right">
          <div className="col-md-5 text-center" 
 ><img src={competitive} alt=""  height="400px" width="400px" className="img-fluid"/></div>
          <div className="col-md-7" >
          <h2><span>Competitive </span> Landscape</h2>
          <p className='font '> In today's competitive business landscape, organizations that prioritize workplace connection have a significant advantage. They are better positioned to attract and retain top talent, drive innovation, and adapt quickly to changing market conditions. Delaying action on improving workplace connection could result in falling behind competitors who are already reaping the benefits of a connected workforce.</p>  </div>
        </div>
        <div className="row align-items-center "  data-aos="fade-right">
          <div className="col-md-5 text-center order-md-2"  
 ><img src={LongTerm} alt="" height="400px" width="300px" className="img-fluid"/></div>
          <div className="col-md-7 order-md-1" >
          <h2>Long-Term <span>Success</span></h2>
          <p className='font '>Investing in workplace connection is not just about addressing immediate challenges; it's also about setting the stage for long-term success. Organizations that prioritize connection build a strong foundation of trust, collaboration, and loyalty among employees, which pays dividends in terms of employee satisfaction, retention, and overall organizational performance.</p>
          
          </div>
        </div>
        <div className="row align-items-center "  data-aos="fade-right">
          <div className="col-md-5 text-center" 
 ><img src={EmployeeHealth} alt=""  height="300px" width="300px" className="img-fluid"/></div>
          <div className="col-md-7" >
          <h2><span>Employee Well-being  </span> and Mental Health</h2>
          <p className='font '> The pandemic has brought mental health and well-being to the forefront, highlighting the importance of connection for overall wellness. Employees who feel connected to their colleagues and supported by their organization are more likely to experience lower levels of stress, anxiety, and burnout. Taking action to improve workplace connection is not just about business success; it's also about caring for the well-being of your employees.</p>  </div>
        </div>
    </section>
    </section>
    <div data-aos="fade-right" id="footer">
     <GetInTouch/>

      <Footer/>
      </div>
   

    </>
  )
}

export default WhyNow