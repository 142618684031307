import { lazy, Suspense, useEffect } from "react";

import { Routes, Route } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import theme from "assets/theme";

import Basic from "layouts/authentication/sign-in";
import ResetPassword from "layouts/authentication/sign-in/ResetPassword";
import InviteLink from "layouts/authentication/sign-in/InviteLink";
import Forget from "layouts/authentication/sign-in/Forget";
import PublicRoutes from "./PublicRoutes";
import FrontPage from "components/Questionare/WelcomeScreen";
import PrivateCompanyRoutes from "./PrivateCompanyRoutes";
import PrivateUserRoutes from "./PrivateUserRoutes";
import PrivateAdminRoute from "./PrivateAdminRoute";
import PublicProfile from "components/UserDashboard/PublicProfile";
import Feedback from "components/Userinterests/Feedback";
import Snapshot from "layouts/Adminprofile/Snapshot";
import FeedbackAlluser from "layouts/Adminprofile/FeedbackAlluser";
import MainSection from "MainComponet/MainSection";
import WhyTheConnectar from "MainComponet/WhyTheConnectar";
import WhatTellUs from "MainComponet/WhatTellUs";
import Methodology from "MainComponet/Methodology";
import WhyNow from "MainComponet/WhyNow";
import ContactUs from "MainComponet/ContactUs";
import PrivacyStatement from "MainComponet/PrivacyStatement";
import { useLocation } from "react-router-dom";
const CompaniesTable = lazy(() => import("layouts/Adminprofile/CompaniesTable"));
const Categories = lazy(() => import("layouts/Category/Categories"));
const AdminProfile = lazy(() => import("layouts/Adminprofile/Profile"));

const UsersTable = lazy(() => import("components/CompanyDashboard/UsersTable"));
const UploadUsers = lazy(() => import("components/CompanyDashboard/uploadUsers"));
const CompanyProfile = lazy(() => import("components/CompanyDashboard/CompanyProfile"));

const Connections = lazy(() => import("components/UserDashboard/Connection"));
const UserProfile = lazy(() => import("components/UserDashboard/UserProfile"));
const Questionere = lazy(() => import("components/Questionare/Questinere"));
import 'MainComponet/css/custom.css'
import Aos from "aos";
Aos.init();

export default function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, [location]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense fallback={<div>Loading Component.........</div>}>
        <Routes>
          <Route element={<PublicRoutes />}>
            <Route path={"/login"} element={<Basic />} />
            <Route path={"/"} element={<MainSection />} />
            <Route path="/authentication/sign-in/forget" element={<Forget />} />
            <Route path="/authentication/sign-in/reset/:id" element={<ResetPassword />} />
            <Route path="/authentication/sign-in/invite/:id" element={<InviteLink />} />
            <Route path="/why-the-connectar" element={<WhyTheConnectar />} />
            <Route path="/why-you-should" element={< WhatTellUs/>}/>
            <Route  path="/methodology" element={<Methodology/>}/>
            <Route path="/why-now" element={<WhyNow/>} />
            <Route path="/contact" element={<ContactUs/>} />
            <Route path="/privacy-policy" element={<PrivacyStatement/>} />
          </Route>

          <Route element={<PrivateAdminRoute />}>
            <Route path="/companies" element={<CompaniesTable />}/>
            <Route path="/weighting" element={<Categories />}  />
            <Route path="/profile" element={<AdminProfile />} />
            <Route path="/snapshot" element={<Snapshot />} />
            <Route path="/feedbacks" element={<FeedbackAlluser />} />
          </Route>

          <Route element={<PrivateCompanyRoutes />}>
            <Route path="/addusers" element={<UsersTable />} />
            <Route path="/uploadusers" element={<UploadUsers />} />
            <Route path="/companyprofile" element={<CompanyProfile />} />
          </Route>

          <Route element={<PrivateUserRoutes />}>
            <Route path="/userprofile" element={<UserProfile />} />
            <Route path="/connections" element={<Connections />} />
            <Route path="/welcomeQuestions" element={<FrontPage />} />

            <Route path="/questionere" element={<Questionere />} />
          </Route>
          <Route path="/feedback/:id" element={<Feedback />} />
          <Route path="/connections/publicprofile/:id" element={<PublicProfile />} />
        </Routes>
      </Suspense>
    </ThemeProvider>
  );
}
