import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import { useState, useEffect, useRef } from "react";
import axios from "axios";

import Footer from "examples/Footer";
import EditIcon from "@mui/icons-material/Edit";
import {
  Autocomplete,
  Avatar,
  Box,
  Chip,
  Container,
  Icon,
  Link,
  Slider,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Nav from "./Navbar";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ResetUserPassword from "./ResetUserPassword";
import Swal from "sweetalert2/dist/sweetalert2.js";
import QuestionModal from "components/Questionare/InterestModal";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import "components/style.css";
import FavoritesForm from "components/Questionare/FavoritesForm";
import Hobbies from "components/Hobbies";
import AvatarEditor from "react-avatar-editor";

import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "#424242",
  },
  title: {
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
    color: "#fff !important",
    padding: "5px 5px",
    fontSize: "20px !important",
  },
}));
const UserProfile = (hanldeComplete) => {
  const [user, setUser] = useState({
    favRestaurant: [],
    favMovie: [],
    favBook: [],
    favCity: [],
  });
  const classes = useStyles();
  const [updated, setUpdated] = useState(true);
  const [showResetModal, setResetShowModal] = useState(false);
  const [isdisabled, setIsDisabled] = useState(true);
  const [showQuestions, setShowQuestions] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [isMovieEditMode, setMovieEditMode] = useState(false);
  const [isBookEditMode, setBookEditMode] = useState(false);
  const [isCityEditMode, setCityEditMode] = useState(false);

  const [isEditModePersonal, setEditModePersonal] = useState(false);
  const [isEditModeAddress, setEditModeAddress] = useState(false);
  const [isEditModeBio, setEditModeBio] = useState(false);

  const [isHobbiesEditMode, setEditedHobbies] = useState(false);
  const [errors, setErrors] = useState({
    restaurant: "",
    book: "",
    movie: "",
    city: "",
  });
  const [emailError, setEmailError] = useState("");

  const [currentInput, setCurrentInput] = useState("");
  const [selectedValues, setSelectedValues] = useState({
    restaurant: [],
    movie: [],
    book: [],
    city: [],
  });

  const [inputValue, setInputValue] = useState({
    restaurant: "",
    movie: "",
    book: "",
    city: "",
  });
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600);
    };
    userProfile();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleInputChange = (event, newValue, fieldName) => {
    setInputValue((prevInputValue) => ({ ...prevInputValue, [fieldName]: newValue }));
  };

  const handleButtonClick = (fieldName) => {
    const trimmedInput = inputValue[fieldName].trim();

    if (trimmedInput && !selectedValues[fieldName].includes(trimmedInput)) {
      setSelectedValues({
        ...selectedValues,
        [fieldName]: [...selectedValues[fieldName], trimmedInput],
      });

      setInputValue({ ...inputValue, [fieldName]: "" });
    } else {
      const fieldValues = selectedValues[fieldName];
      const editedValues = fieldValues.map((value) => {
        if (value === currentInput) {
          return trimmedInput;
        } else {
          return value;
        }
      });
      setSelectedValues({ ...selectedValues, [fieldName]: editedValues });
    }
  };

  const handleAutocompleteChange = (event, value, field, max, min) => {
    if (value.length >= min && value.length <= max) {
      setSelectedValues({ ...selectedValues, [field]: value });
      setErrors({ ...errors, [field]: "" });
    } else if (value.length > max) {
      setSelectedValues({ ...selectedValues, [field]: value });
      setErrors({
        ...errors,
        [field]: `Please select a maximum of ${max} ${field}s.`,
      });
    } else {
      setSelectedValues({ ...selectedValues, [field]: value });
      setErrors({
        ...errors,
        [field]: `Please select at least ${min} ${field}s.`,
      });
    }
  };

  const handleEditClick = () => {
    setEditMode(!isEditMode);
  };

  const handleMovieEditClick = () => {
    setMovieEditMode(!isMovieEditMode);
  };

  const handleBookEditClick = () => {
    setBookEditMode(!isBookEditMode);
  };

  const handleCityEditClick = () => {
    setCityEditMode(!isCityEditMode);
  };

  const handleSaveClick = () => {
    const token = localStorage.getItem("token");
    const updatedUser = {
      ...user,
      favRestaurant: selectedValues.restaurant,
      favMovie: selectedValues.movie,
      favBook: selectedValues.book,
      favCity: selectedValues.city,
    };

    if (
      !updatedUser.favRestaurant ||
      !updatedUser.favMovie ||
      !updatedUser.favBook ||
      !updatedUser.favCity
    ) {
      return;
    }

    if (user?._id) {
      if (user.iscomplete) {
        setShowQuestions((updatedUser.favRestaurant || []).length < 5);
      } else {
        navigate("/questionere");
      }
    }

    axios
      .post(`${process.env.REACT_APP_API_PATH}/updateProfile`, { user: updatedUser, token })
      .then((res) => {
        if (res.data.status === 200) {
          Swal.fire("Profile Updated!", "", "success");
          const userData = res.data.data;
          setUser(userData);

          setSelectedValues({
            restaurant: userData.favRestaurant || [],
            movie: userData.favMovie || [],
            book: userData.favBook || [],
            city: userData.favCity || [],
          });
          handleClose();
        }
      })
      .catch((err) => console.log(err));
  };

  const navigate = useNavigate();

  const userProfile = () => {
    let token = localStorage.getItem("token");

    if (token) {
      axios
        .post(`${process.env.REACT_APP_API_PATH}/validuser`, { token })
        .then((res) => {
          if (res.data.status == 200) {
            const userData = res.data.data;
            setUser(userData);

            setSelectedValues({
              restaurant: userData.favRestaurant || res.data.data,
              movie: userData.favMovie || res.data.data,
              book: userData.favBook || res.data.data,
              city: userData.favCity || res.data.data,
            });
          }
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    userProfile();
  }, []);

  const handleChange = (e) => {
    setIsDisabled(false);
    setUser({ ...user, [e.target.name]: e.target.value });
    if (e.target.name === "email") {
      const email = e.target.value;
      const emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
      if (emailRegex.test(email)) {
        setEmailError("");
      } else {
        setEmailError("Enter valid Email!");
      }
    }
  };

  const updateProfile = () => {
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
    const { email } = user;

    if (!email || !emailRegex.test(email)) {
      Swal.fire("Invalid Email!", "Please enter a valid email address.", "error");
      return;
    }
    setUpdated(true);

    setIsDisabled(true);
    const token = localStorage.getItem("token");
    Swal.fire("Profile Updated!", "", "success");

    axios
      .post(`${process.env.REACT_APP_API_PATH}/updateprofile`, { user, token })
      .then((res) => {
        if (res.data.status == 200) {
          setUser(res.data.data);
          setSelectedValues(res.data.data);
          setEditModeBio(false);
        }
      })
      .catch((err) => console.log(err));

    handleClose();
    handleCloseModal();
  };
  const ResetPassword = (values) => {
    const token = localStorage.getItem("token");

    axios

      .post(`${process.env.REACT_APP_API_PATH}/resetPassword`, { values, token })
      .then((res) => {
        if (res.data.status == 200) {
          localStorage.clear();
          setResetShowModal(false);
          navigate("/");
          Swal.fire("Done!", "you can now logged in with new password", "success");
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const handleCloseModal = () => {
    userProfile();
  };
  const closeModal = () => {
    setResetShowModal(false);
  };

  const modalopen = () => {
    setResetShowModal(true);
  };

  useEffect(() => {
    if (user?._id) {
      if (user.iscomplete) {
        setShowQuestions(user.favRestaurant.length < 5);
        setShowQuestions(user.favRestaurant.length < 5);
      } else {
        navigate("/questionere");
      }
    }
  }, [user]);

  const handleModal = () => {
    navigate("/userprofile");
    setShowQuestions(false);
    hanldeComplete();
  };

  const handleClose = () => {
    userProfile();
    setEditMode(false);
    setBookEditMode(false);
    setMovieEditMode(false);
    setCityEditMode(false);
    setEditModePersonal(false);
    setEditModeAddress(false);
    setEditModeBio(false);

    setEditedHobbies(false);
  };

  // image upload

  var editor = "";
  const [picture, setPicture] = useState({
    cropperOpen: false,
    img: null,
    zoom: 2,
    croppedImg: "",
  });

  const handleSlider = (event, value) => {
    setPicture({
      ...picture,
      zoom: value,
    });
  };

  const handleCancel = (event) => {
    event.preventDefault();
    setPicture({
      ...picture,

      cropperOpen: false,
    });
  };

  const setEditorRef = (ed) => {
    editor = ed;
  };
  const handleSave = (e) => {
    e.preventDefault();

    if (setEditorRef) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL("image/jpeg", 1.0);

      setPicture({
        ...picture,
        img: null,
        cropperOpen: false,
      });

      const token = localStorage.getItem("token");
      const file = dataURItoBlob(croppedImg);

      const formData = new FormData();
      formData.append("file", file, "image.jpg");

      axios
        .post(`${process.env.REACT_APP_API_PATH}/image/${token}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          userProfile();
          Swal.fire("Image Updated!", "", "success");
        })
        .catch((err) => console.log(err));
    }
  };

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  };

  const handleFileChange = (e) => {
    let url = URL.createObjectURL(e.target.files[0]);
    // console.log(url);
    setPicture({
      ...picture,
      img: url,
      cropperOpen: true,
    });
  };
  useEffect(() => {
    if (!user?.bio) {
      setEditModeBio(true);
    } else {
      setBookEditMode(false);
    }
  }, [user]);
  return (
    <>
      <Nav data={user} isUserProfile={true} isPublicProfile={false} />
      <section style={{ backgroundColor: "#fff" }}>
        <Container>
          <MDBox py={3}>
            <MDBox>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "7vh",
                }}
              ></Box>
              <Grid container spacing={0} justifyContent="center">
                <Grid item xs={12} md={12} lg={1}></Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <MDBox mt={5} mb={3}>
                    <Grid container>
                      <Grid item xs={12} md={12} xl={12}>
                        <Grid item xs={12} sm={12} md={12}>
                          <Grid
                            container
                            style={{
                              border: "1px solid rgb(0 0 0 / 11%) ",
                              borderRadius: "10px",
                              // boxShadow: 1,
                              marginTop: "10px",
                            }}
                          >
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={12}
                              style={{ marginRight: "20px", marginTop: "20px" }}
                            >
                              <Link
                                href="#"
                                onClick={modalopen}
                                style={{
                                  textDecoration: "none",
                                  color: "#0d6efd",
                                  float: "right",
                                  fontSize: "18px",
                                }}
                              >
                                Reset Password
                              </Link>
                            </Grid>
                            <Grid item xs={12} sm={6} md={12} mb={3}>
                              <MDBox
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  flexDirection: "column",
                                  padding: "20px",
                                }}
                              >
                                <div style={{ position: "relative", textAlign: "center" }}>
                                  <label htmlFor="upload-button">
                                    {picture.cropperOpen ? (
                                      <>
                                        <AvatarEditor
                                          ref={setEditorRef}
                                          image={picture.img}
                                          width={150}
                                          height={150}
                                          border={10}
                                          color={[73, 163, 241]}
                                          rotate={0}
                                          scale={picture.zoom}
                                          style={{ borderRadius: "50%" }}
                                          borderRadius={150}
                                        />
                                        <Slider
                                          aria-label="raceSlider"
                                          value={picture.zoom}
                                          min={1}
                                          max={6}
                                          step={0.1}
                                          onChange={handleSlider}
                                        ></Slider>
                                      </>
                                    ) : (
                                      <>
                                        {user?.image ? (
                                          <img
                                            src={user?.image}
                                            alt="dummy"
                                            width="150"
                                            height="150"
                                            style={{
                                              borderRadius: "50%",
                                              cursor: "pointer",
                                            }}
                                          />
                                        ) : (
                                          <Tooltip title="Image Upload">
                                            <Avatar
                                              htmlFor="image-upload"
                                              label="Image Upload"
                                              sx={{
                                                height: 150,
                                                mb: 2,
                                                width: 150,
                                                cursor: "pointer",
                                              }}
                                              style={{ height: 150, width: 150 }}
                                            />
                                          </Tooltip>
                                        )}
                                        <span
                                          style={{
                                            position: "absolute",
                                            overflow: "hidden",
                                            padding: "3px 5px",
                                            cursor: "pointer",
                                            bottom: "0",
                                            left: "50%",
                                            transform: "translateX(-50%)",
                                            fontWeight: "600",
                                            color: "#333",
                                            backgroundColor: "#fff",
                                            borderRadius: "50px",
                                            display: "inline-block",
                                            width: "110px",
                                            fontSize: "13px",
                                          }}
                                        >
                                          Upload Image <FileUploadIcon />
                                        </span>
                                      </>
                                    )}
                                  </label>
                                  <input
                                    type="file"
                                    id="upload-button"
                                    style={{ display: "none" }}
                                    onChange={(e) => handleFileChange(e)}
                                  />
                                </div>
                                {picture.cropperOpen && (
                                  <Box mx={2} mt={-3} py={3} px={2}>
                                    <Box>
                                      <Grid container spacing={1}>
                                        <Grid item xs={12} md={6} xl={6}>
                                          <Box mb={2}>
                                            <MDButton
                                              variant="gradient"
                                              color="info"
                                              fullWidth
                                              onClick={handleCancel}
                                            >
                                              Cancel
                                            </MDButton>
                                          </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6} xl={6}>
                                          <Box mb={2}>
                                            <MDButton
                                              variant="gradient"
                                              color="info"
                                              fullWidth
                                              onClick={handleSave}
                                            >
                                              Save
                                            </MDButton>
                                            <ToastContainer />
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </Box>
                                )}
                              </MDBox>
                            </Grid>
                          </Grid>
                          <MDBox
                            component="fieldset"
                            mt={2}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              padding: "5px",
                              border: "none",
                            }}
                          >
                            <Box
                              component="fieldset"
                              sx={{ fontSize: "1.5rem", fontWeight: "bold" }}
                              className="filedset_border"
                            >
                              <legend style={{ marginLeft: "15px" }}>Personal Information </legend>
                              <Icon
                                variant="text"
                                color="info"
                                onClick={() => setEditModePersonal(!isEditModePersonal)}
                                sx={{
                                  marginLeft: "10px",
                                  cursor: isEditModePersonal === undefined ? "pointer" : "default",
                                  float: "right",
                                  marginRight: "20px",
                                  marginTop: "-2%",
                                }}
                              >
                                {isEditModePersonal
                                  ? ""
                                  : isEditModePersonal !== undefined && (
                                      <EditIcon
                                        sx={{ cursor: "pointer", fontSize: "16px !important" }}
                                      />
                                    )}
                              </Icon>

                              <Grid
                                container
                                spacing={1}
                                style={{ marginLeft: "10px", paddingTop: "1px !important" }}
                              >
                                <Grid item xs={12} md={6} xl={6}>
                                  <Box>
                                    <Typography
                                      variant="subtitle2"
                                      color="textSecondary"
                                      fontSize={"18px"}
                                    >
                                      First Name
                                    </Typography>
                                    {isEditModePersonal ? (
                                      <input
                                        className="filedset_border form-control"
                                        variant="outlined"
                                        name="firstname"
                                        value={user?.firstname ? user?.firstname : ""}
                                        onChange={handleChange}
                                        style={{ width: isSmallScreen ? "91%" : "100%" }}
                                        fullWidth
                                      />
                                    ) : (
                                      <Typography
                                        variant="body1"
                                        color="textPrimary"
                                        sx={{ marginTop: "2px" }}
                                      >
                                        {user?.firstname ? user?.firstname : ""}
                                      </Typography>
                                    )}
                                  </Box>
                                </Grid>
                                <Grid item xs={12} md={5} xl={5}>
                                  <Box mb={2}>
                                    <Typography
                                      variant="subtitle2"
                                      color="textSecondary"
                                      fontSize={"18px"}
                                    >
                                      Last Name
                                    </Typography>
                                    {isEditModePersonal ? (
                                      <input
                                        className="filedset_border form-control"
                                        variant="outlined"
                                        name="lastname"
                                        value={user?.lastname ? user?.lastname : ""}
                                        onChange={handleChange}
                                        fullWidth
                                        style={{ width: isSmallScreen ? "91%" : "100%" }}
                                      />
                                    ) : (
                                      <Typography
                                        variant="body1"
                                        color="textPrimary"
                                        sx={{ marginTop: "2px" }}
                                      >
                                        {user?.lastname ? user?.lastname : ""}
                                      </Typography>
                                    )}
                                  </Box>
                                </Grid>
                                <Grid item xs={12} md={6} xl={6}>
                                  <Box>
                                    <Typography
                                      variant="subtitle2"
                                      color="textSecondary"
                                      fontSize={"18px"}
                                    >
                                      Contact
                                    </Typography>
                                    {isEditModePersonal ? (
                                      <input
                                        className="filedset_border form-control"
                                        variant="outlined"
                                        name="contact"
                                        value={user?.contact ? user?.contact : ""}
                                        onChange={handleChange}
                                        fullWidth
                                        style={{ width: isSmallScreen ? "91%" : "100%" }}
                                      />
                                    ) : (
                                      <Typography
                                        variant="body1"
                                        color="textPrimary"
                                        sx={{ marginTop: "2px" }}
                                      >
                                        {user?.contact ? user?.contact : ""}
                                      </Typography>
                                    )}
                                  </Box>
                                </Grid>
                                <Grid item xs={12} md={5} xl={5}>
                                  <Box mb={2}>
                                    <Typography
                                      variant="subtitle2"
                                      color="textSecondary"
                                      fontSize={"18px"}
                                    >
                                      Email
                                    </Typography>
                                    {isEditModePersonal ? (
                                      <input
                                        className="filedset_border form-control"
                                        variant="outlined"
                                        name="email"
                                        value={user?.email ? user?.email : ""}
                                        onChange={handleChange}
                                        fullWidth
                                        style={{ width: isSmallScreen ? "91%" : "100%" }}
                                      />
                                    ) : (
                                      <Typography
                                        variant="body1"
                                        color="textPrimary"
                                        sx={{ marginTop: "2px" }}
                                      >
                                        {user?.email ? user?.email : ""}
                                      </Typography>
                                    )}
                                    <span style={{ fontSize: "14px", color: "red" }}>
                                      {emailError}
                                    </span>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} md={6} xl={6}>
                                  <Box mb={2}>
                                    <Typography
                                      variant="subtitle2"
                                      color="textSecondary"
                                      fontSize={"18px"}
                                    >
                                      Role
                                    </Typography>
                                    {isEditModePersonal ? (
                                      <input
                                        className="filedset_border form-control"
                                        variant="outlined"
                                        name="userRole"
                                        value={user?.userRole ? user?.userRole : ""}
                                        onChange={handleChange}
                                        fullWidth
                                        style={{ width: isSmallScreen ? "91%" : "100%" }}
                                      />
                                    ) : (
                                      <Typography
                                        variant="body1"
                                        color="textPrimary"
                                        sx={{ marginTop: "2px" }}
                                      >
                                        {user?.userRole ? user?.userRole : ""}
                                      </Typography>
                                    )}
                                  </Box>
                                </Grid>
                              </Grid>

                              {isEditModePersonal && (
                                <>
                                  <MDBox mt={2} mb={2}>
                                    <MDButton
                                      style={{
                                        marginLeft: "20px",
                                        backgroundColor: "#0d6efd",
                                        color: "white",
                                      }}
                                      variant="gradient"
                                      onClick={updateProfile}
                                    >
                                      Update
                                    </MDButton>
                                    <MDButton
                                      component="a"
                                      style={{
                                        backgroundColor: "transparent",
                                        color: "#0d6efd",
                                        textDecoration: "underline",
                                        textTransform: "none",
                                        fontSize: "16px",
                                      }}
                                      onClick={handleClose}
                                    >
                                      Cancel
                                    </MDButton>
                                  </MDBox>
                                </>
                              )}
                            </Box>
                          </MDBox>
                          <MDBox
                            component="fieldset"
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              padding: "5px",
                              border: "none",
                            }}
                          >
                            <Box
                              component="fieldset"
                              className="filedset_border"
                              sx={{ fontSize: "1.5rem", fontWeight: "bold" }}
                            >
                              <legend style={{ marginLeft: "15px" }}> Address </legend>
                              <Box>
                                <Icon
                                  variant="text"
                                  color="info"
                                  onClick={() => setEditModeAddress(!isEditModeAddress)}
                                  sx={{
                                    marginLeft: "10px",
                                    cursor: isEditModeAddress === undefined ? "pointer" : "default",
                                    float: "right",
                                    marginRight: "20px",
                                    marginTop: "-2%",
                                  }}
                                >
                                  {isEditModeAddress
                                    ? ""
                                    : isEditModeAddress !== undefined && (
                                        <EditIcon
                                          sx={{ cursor: "pointer", fontSize: "16px !important" }}
                                        />
                                      )}
                                </Icon>
                                <Grid container spacing={1} style={{ marginLeft: "10px" }}>
                                  <Grid item xs={12} md={6} xl={6}>
                                    <Box>
                                      <Typography
                                        variant="subtitle2"
                                        color="textSecondary"
                                        fontSize={"18px"}
                                      >
                                        Country
                                      </Typography>
                                      {isEditModeAddress ? (
                                        <input
                                          className="filedset_border form-control"
                                          variant="outlined"
                                          name="country"
                                          value={user?.country ? user?.country : ""}
                                          onChange={handleChange}
                                          fullWidth
                                          style={{ width: isSmallScreen ? "91%" : "100%" }}
                                        />
                                      ) : (
                                        <Typography
                                          variant="body1"
                                          color="textPrimary"
                                          sx={{ marginTop: "2px" }}
                                        >
                                          {user?.country ? user?.country : ""}
                                        </Typography>
                                      )}
                                    </Box>
                                  </Grid>
                                  <Grid item xs={12} md={5} xl={5}>
                                    <Box mb={2}>
                                      <Typography
                                        variant="subtitle2"
                                        color="textSecondary"
                                        fontSize={"18px"}
                                      >
                                        City
                                      </Typography>
                                      {isEditModeAddress ? (
                                        <input
                                          className="filedset_border form-control"
                                          variant="outlined"
                                          name="city"
                                          value={user?.city ? user?.city : ""}
                                          onChange={handleChange}
                                          fullWidth
                                          style={{ width: isSmallScreen ? "91%" : "100%" }}
                                        />
                                      ) : (
                                        <Typography
                                          variant="body1"
                                          color="textPrimary"
                                          sx={{ marginTop: "2px" }}
                                        >
                                          {user?.city ? user?.city : ""}
                                        </Typography>
                                      )}
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                              {isEditModeAddress && (
                                <>
                                  <MDBox mt={2} mb={2}>
                                    <MDButton
                                      style={{
                                        marginLeft: "20px",
                                        backgroundColor: "#0d6efd",
                                        color: "white",
                                      }}
                                      variant="gradient"
                                      onClick={updateProfile}
                                    >
                                      Update
                                    </MDButton>
                                    <MDButton
                                      component="a"
                                      style={{
                                        backgroundColor: "transparent",
                                        color: "#0d6efd",
                                        textDecoration: "underline",
                                        textTransform: "none",
                                        fontSize: "16px",
                                      }}
                                      onClick={handleClose}
                                    >
                                      Cancel
                                    </MDButton>
                                  </MDBox>
                                </>
                              )}
                            </Box>
                          </MDBox>

                          <Grid container spacing={1}>
                            <Grid item xs={12} md={12} xl={12}>
                              <MDBox>
                                <MDBox
                                  component="fieldset"
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    border: "none",
                                    padding: "5px",
                                  }}
                                >
                                  <Box
                                    component="fieldset"
                                    className="filedset_border"
                                    sx={{ fontSize: "1.5rem", fontWeight: "bold" }}
                                  >
                                    <legend style={{ marginLeft: "15px" }}> Bio </legend>
                                    <Box>
                                      <Icon
                                        variant="text"
                                        color="info"
                                        onClick={() => setEditModeBio(!isEditModeBio)}
                                        sx={{
                                          cursor:
                                            isEditModeBio === undefined ? "pointer" : "default",
                                          float: "right",
                                          marginRight: "20px",
                                          marginTop: "-2%",
                                        }}
                                      >
                                        {isEditModeBio
                                          ? ""
                                          : isEditModeBio !== undefined && (
                                              <EditIcon
                                                sx={{
                                                  cursor: "pointer",
                                                  fontSize: "16px !important",
                                                }}
                                              />
                                            )}
                                      </Icon>

                                      <Grid container spacing={1} style={{ marginLeft: "10px" }}>
                                        <Grid item xs={12} md={11} xl={11}>
                                          <MDBox mb={2}>
                                            {isEditModeBio ? (
                                              <>
                                                <textarea
                                                  className="filedset_border form-control"
                                                  variant="outlined"
                                                  multiline
                                                  rows={4}
                                                  name="bio"
                                                  value={user?.bio ? user?.bio : ""}
                                                  onChange={handleChange}
                                                  fullWidth
                                                  required
                                                  style={{
                                                    width: isSmallScreen ? "91%" : "100%",
                                                    minHeight: "4rem",
                                                    resize: "none",
                                                  }}
                                                />

                                                {!user?.bio && (
                                                  <Typography variant="h5" color="error" mt={1}>
                                                    Bio is required
                                                  </Typography>
                                                )}
                                              </>
                                            ) : (
                                              <Typography variant="body1" color="textPrimary">
                                                {user?.bio ? user?.bio : ""}
                                              </Typography>
                                            )}
                                            {isEditModeBio && (
                                              <>
                                                <MDBox mt={2} mb={2}>
                                                  <MDButton
                                                    style={{
                                                      backgroundColor: "#0d6efd",
                                                      color: "white",
                                                    }}
                                                    variant="gradient"
                                                    onClick={updateProfile}
                                                  >
                                                    Update
                                                  </MDButton>
                                                  <MDButton
                                                    component="a"
                                                    style={{
                                                      backgroundColor: "transparent",
                                                      color: "#0d6efd",
                                                      textDecoration: "underline",
                                                      textTransform: "none",
                                                      fontSize: "16px",
                                                    }}
                                                    onClick={handleClose}
                                                  >
                                                    Cancel
                                                  </MDButton>
                                                </MDBox>
                                              </>
                                            )}
                                          </MDBox>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </Box>
                                </MDBox>
                              </MDBox>
                            </Grid>
                          </Grid>

                          <Grid container spacing={1}>
                            <Grid item xs={12} md={12} xl={12}>
                              <MDBox>
                                <MDBox
                                  component="fieldset"
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: "5px",
                                    border: "none",
                                  }}
                                >
                                  <Box
                                    component="fieldset"
                                    className="filedset_border"
                                    sx={{ fontSize: "1.5rem", fontWeight: "bold" }}
                                  >
                                    <legend style={{ marginLeft: "15px" }}> Restaurants </legend>
                                    <Box>
                                      <Icon
                                        variant="text"
                                        color="info"
                                        onClick={handleEditClick}
                                        sx={{
                                          cursor: isEditMode === undefined ? "pointer" : "default",
                                          float: "right",
                                          marginRight: "20px",
                                          marginTop: "-2%",
                                        }}
                                      >
                                        {isEditMode
                                          ? ""
                                          : isEditMode !== undefined && (
                                              <EditIcon
                                                sx={{
                                                  cursor: "pointer",
                                                  fontSize: "16px !important",
                                                }}
                                              />
                                            )}
                                      </Icon>
                                      <Grid container spacing={1} style={{ marginLeft: "10px" }}>
                                        <Grid item xs={12} md={11} xl={11}>
                                          <MDBox mb={1}>
                                            {isEditMode ? (
                                              <>
                                                <>
                                                  <Autocomplete
                                                    freeSolo
                                                    multiple
                                                    options={[]}
                                                    value={selectedValues?.restaurant || []}
                                                    onChange={(event, value) =>
                                                      handleAutocompleteChange(
                                                        event,
                                                        value,
                                                        "restaurant",
                                                        10,
                                                        5
                                                      )
                                                    }
                                                    inputValue={inputValue.restaurant}
                                                    onInputChange={(event, newValue) =>
                                                      handleInputChange(
                                                        event,
                                                        newValue,
                                                        "restaurant"
                                                      )
                                                    }
                                                    renderInput={(params) => (
                                                      <TextField
                                                        {...params}
                                                        id="outlined-basic"
                                                        label="Restaurants"
                                                        variant="outlined"
                                                        value={inputValue.restaurant}
                                                        style={{
                                                          width: "100%",
                                                          marginBottom: "20px",
                                                        }}
                                                        onChange={(event) =>
                                                          setCurrentInput(event.target.value)
                                                        }
                                                      />
                                                    )}
                                                    style={{
                                                      width: isSmallScreen ? "91%" : "100%",
                                                    }}
                                                  />
                                                  {errors.restaurant && (
                                                    <Typography variant="body2" color="error">
                                                      {errors.restaurant}
                                                    </Typography>
                                                  )}
                                                </>
                                              </>
                                            ) : (
                                              <MDTypography
                                                display="inline"
                                                variant="body2"
                                                verticalAlign="middle"
                                              >
                                                {user?.favRestaurant &&
                                                  user.favRestaurant
                                                    .slice(0, 10)
                                                    .map((restaurant, index) => (
                                                      <Chip
                                                        key={index}
                                                        label={restaurant}
                                                        variant="outlined"
                                                        style={{
                                                          marginRight: "5px",
                                                          marginBottom: "5px",
                                                          cursor: "pointer",
                                                          fontSize: "16px",
                                                          fontWeight: "bolder",
                                                        }}
                                                      />
                                                    ))}
                                              </MDTypography>
                                            )}
                                            {isEditMode && (
                                              <>
                                                <MDBox mt={2} mb={2}>
                                                  <MDButton
                                                    component="a"
                                                    style={{
                                                      backgroundColor: "transparent",
                                                      color: "#0d6efd",
                                                      textDecoration: "underline",
                                                      textTransform: "none",
                                                      fontSize: "16px",
                                                    }}
                                                    onClick={() => handleButtonClick("restaurant")}
                                                  >
                                                    Add
                                                  </MDButton>
                                                  <MDButton
                                                    style={{
                                                      backgroundColor: "#0d6efd",
                                                      color: "white",
                                                    }}
                                                    variant="gradient"
                                                    onClick={handleSaveClick}
                                                    disabled={
                                                      selectedValues.restaurant.length < 5 ||
                                                      selectedValues.restaurant.length > 10
                                                    }
                                                  >
                                                    Update
                                                  </MDButton>
                                                  <MDButton
                                                    component="a"
                                                    style={{
                                                      backgroundColor: "transparent",
                                                      color: "#0d6efd",
                                                      textDecoration: "underline",
                                                      textTransform: "none",
                                                      fontSize: "16px",
                                                    }}
                                                    onClick={handleClose}
                                                  >
                                                    Cancel
                                                  </MDButton>
                                                </MDBox>
                                              </>
                                            )}
                                          </MDBox>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </Box>
                                </MDBox>
                              </MDBox>
                            </Grid>
                          </Grid>

                          <Grid container spacing={1}>
                            <Grid item xs={12} md={12} xl={12}>
                              <MDBox>
                                <MDBox
                                  component="fieldset"
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: "5px",
                                    border: "none",
                                  }}
                                >
                                  <Box
                                    component="fieldset"
                                    className="filedset_border"
                                    sx={{ fontSize: "1.5rem", fontWeight: "bold" }}
                                  >
                                    <legend style={{ marginLeft: "15px" }}> Books </legend>
                                    <Box>
                                      <Icon
                                        variant="text"
                                        color="info"
                                        onClick={handleBookEditClick}
                                        sx={{
                                          cursor:
                                            isBookEditMode === undefined ? "pointer" : "default",
                                          float: "right",
                                          marginRight: "20px",
                                          marginTop: "-2%",
                                        }}
                                      >
                                        {isBookEditMode
                                          ? ""
                                          : isBookEditMode !== undefined && (
                                              <EditIcon
                                                sx={{
                                                  cursor: "pointer",
                                                  fontSize: "16px !important",
                                                }}
                                              />
                                            )}
                                      </Icon>
                                      <Grid container spacing={1} style={{ marginLeft: "10px" }}>
                                        <Grid item xs={12} md={11} xl={11}>
                                          <MDBox mb={1}>
                                            {isBookEditMode ? (
                                              <>
                                                <Autocomplete
                                                  freeSolo
                                                  multiple
                                                  options={[]}
                                                  value={selectedValues?.book || []}
                                                  onChange={(event, value) =>
                                                    handleAutocompleteChange(
                                                      event,
                                                      value,
                                                      "book",
                                                      10,
                                                      5
                                                    )
                                                  }
                                                  inputValue={inputValue.book}
                                                  onInputChange={(event, newValue) =>
                                                    handleInputChange(event, newValue, "book")
                                                  }
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                      id="outlined-basic"
                                                      label="Books"
                                                      variant="outlined"
                                                      style={{
                                                        width: "100%",
                                                        marginBottom: "20px",
                                                      }}
                                                      onChange={(event) =>
                                                        setCurrentInput(event.target.value)
                                                      }
                                                    />
                                                  )}
                                                  style={{ width: isSmallScreen ? "91%" : "100%" }}
                                                />
                                                {errors.book && (
                                                  <Typography variant="body2" color="error">
                                                    {errors.book}
                                                  </Typography>
                                                )}
                                              </>
                                            ) : (
                                              <MDTypography
                                                display="inline"
                                                variant="body2"
                                                verticalAlign="middle"
                                              >
                                                {user?.favBook &&
                                                  user.favBook.slice(0, 10).map((book, index) => (
                                                    <Chip
                                                      key={index}
                                                      label={book}
                                                      variant="outlined"
                                                      style={{
                                                        marginRight: "5px",
                                                        marginBottom: "5px",
                                                        cursor: "pointer",
                                                        fontSize: "16px",
                                                        fontWeight: "bolder",
                                                      }}
                                                    />
                                                  ))}
                                              </MDTypography>
                                            )}
                                            {isBookEditMode && (
                                              <>
                                                <MDBox mt={2} mb={2}>
                                                  <MDButton
                                                    component="a"
                                                    style={{
                                                      backgroundColor: "transparent",
                                                      color: "#0d6efd",
                                                      textDecoration: "underline",
                                                      textTransform: "none",
                                                      fontSize: "16px",
                                                    }}
                                                    onClick={() => handleButtonClick("book")}
                                                  >
                                                    Add
                                                  </MDButton>
                                                  <MDButton
                                                    style={{
                                                      backgroundColor: "#0d6efd",
                                                      color: "white",
                                                    }}
                                                    variant="gradient"
                                                    onClick={handleSaveClick}
                                                    disabled={
                                                      selectedValues.book.length < 5 ||
                                                      selectedValues.book.length > 10
                                                    }
                                                  >
                                                    Update
                                                  </MDButton>
                                                  <MDButton
                                                    component="a"
                                                    style={{
                                                      backgroundColor: "transparent",
                                                      color: "#0d6efd",
                                                      textDecoration: "underline",
                                                      textTransform: "none",
                                                      fontSize: "16px",
                                                    }}
                                                    onClick={handleClose}
                                                  >
                                                    Cancel
                                                  </MDButton>
                                                </MDBox>
                                              </>
                                            )}
                                          </MDBox>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </Box>
                                </MDBox>
                              </MDBox>
                            </Grid>
                          </Grid>

                          <Grid container spacing={1}>
                            <Grid item xs={12} md={12} xl={12}>
                              <MDBox mb={1}>
                                <MDBox
                                  component="fieldset"
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: "5px",
                                    border: "none",
                                  }}
                                >
                                  <Box
                                    component="fieldset"
                                    className="filedset_border"
                                    sx={{ fontSize: "1.5rem", fontWeight: "bold" }}
                                  >
                                    <legend style={{ marginLeft: "15px" }}> Movies </legend>
                                    <Box>
                                      <Icon
                                        variant="text"
                                        color="info"
                                        onClick={handleMovieEditClick}
                                        sx={{
                                          cursor:
                                            isMovieEditMode === undefined ? "pointer" : "default",
                                          float: "right",
                                          marginRight: "20px",
                                          marginTop: "-2%",
                                        }}
                                      >
                                        {isMovieEditMode
                                          ? ""
                                          : isMovieEditMode !== undefined && (
                                              <EditIcon
                                                sx={{
                                                  cursor: "pointer",
                                                  fontSize: "16px !important",
                                                }}
                                              />
                                            )}
                                      </Icon>
                                      <Grid container spacing={1} style={{ marginLeft: "10px" }}>
                                        <Grid item xs={12} md={11} xl={11}>
                                          <MDBox mb={1}>
                                            {isMovieEditMode ? (
                                              <>
                                                <Autocomplete
                                                  freeSolo
                                                  multiple
                                                  options={[]}
                                                  value={selectedValues?.movie || []}
                                                  onChange={(event, value) =>
                                                    handleAutocompleteChange(
                                                      event,
                                                      value,
                                                      "movie",
                                                      10,
                                                      5
                                                    )
                                                  }
                                                  inputValue={inputValue.movie}
                                                  onInputChange={(event, newValue) =>
                                                    handleInputChange(event, newValue, "movie")
                                                  }
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                      id="outlined-basic"
                                                      label="Movies"
                                                      variant="outlined"
                                                      style={{
                                                        width: "100%",
                                                        marginBottom: "20px",
                                                      }}
                                                      onChange={(event) =>
                                                        setCurrentInput(event.target.value)
                                                      }
                                                    />
                                                  )}
                                                  style={{ width: isSmallScreen ? "91%" : "100%" }}
                                                />
                                                {errors.movie && (
                                                  <Typography variant="body2" color="error">
                                                    {errors.movie}
                                                  </Typography>
                                                )}
                                              </>
                                            ) : (
                                              <MDTypography
                                                display="inline"
                                                variant="body2"
                                                verticalAlign="middle"
                                              >
                                                {user?.favMovie &&
                                                  user.favMovie.slice(0, 10).map((movie, index) => (
                                                    <Chip
                                                      key={index}
                                                      label={movie}
                                                      variant="outlined"
                                                      style={{
                                                        marginRight: "5px",
                                                        marginBottom: "5px",
                                                        cursor: "pointer",
                                                        fontSize: "16px",
                                                        fontWeight: "bolder",
                                                      }}
                                                    />
                                                  ))}
                                              </MDTypography>
                                            )}
                                            {isMovieEditMode && (
                                              <>
                                                <MDBox mt={2} mb={2}>
                                                  <MDButton
                                                    component="a"
                                                    style={{
                                                      backgroundColor: "transparent",
                                                      color: "#0d6efd",
                                                      textDecoration: "underline",
                                                      textTransform: "none",
                                                      fontSize: "16px",
                                                    }}
                                                    onClick={() => handleButtonClick("movie")}
                                                  >
                                                    Add
                                                  </MDButton>
                                                  <MDButton
                                                    style={{
                                                      backgroundColor: "#0d6efd",
                                                      color: "white",
                                                    }}
                                                    variant="gradient"
                                                    onClick={handleSaveClick}
                                                    disabled={
                                                      selectedValues.movie.length < 5 ||
                                                      selectedValues.movie.length > 10
                                                    }
                                                  >
                                                    Update
                                                  </MDButton>
                                                  <MDButton
                                                    component="a"
                                                    style={{
                                                      backgroundColor: "transparent",
                                                      color: "#0d6efd",
                                                      textDecoration: "underline",
                                                      textTransform: "none",
                                                      fontSize: "16px",
                                                    }}
                                                    onClick={handleClose}
                                                  >
                                                    Cancel
                                                  </MDButton>
                                                </MDBox>
                                              </>
                                            )}
                                          </MDBox>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </Box>
                                </MDBox>
                              </MDBox>
                            </Grid>
                          </Grid>

                          <Grid container spacing={1}>
                            <Grid item xs={12} md={12} xl={12}>
                              <MDBox>
                                <MDBox
                                  component="fieldset"
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: "5px",
                                    border: "none",
                                  }}
                                >
                                  <Box
                                    component="fieldset"
                                    className="filedset_border"
                                    sx={{ fontSize: "1.5rem", fontWeight: "bold" }}
                                  >
                                    <legend style={{ marginLeft: "15px" }}> Cities </legend>
                                    <Box>
                                      <Icon
                                        variant="text"
                                        color="info"
                                        onClick={handleCityEditClick}
                                        sx={{
                                          cursor:
                                            isCityEditMode === undefined ? "pointer" : "default",
                                          float: "right",
                                          marginRight: "20px",
                                          marginTop: "-2%",
                                        }}
                                      >
                                        {isCityEditMode
                                          ? ""
                                          : isCityEditMode !== undefined && (
                                              <EditIcon
                                                sx={{
                                                  cursor: "pointer",
                                                  fontSize: "16px !important",
                                                }}
                                              />
                                            )}
                                      </Icon>
                                      <Grid container spacing={1} style={{ marginLeft: "10px" }}>
                                        <Grid item xs={12} md={11} xl={11}>
                                          <MDBox mb={1}>
                                            {isCityEditMode ? (
                                              <>
                                                <Autocomplete
                                                  freeSolo
                                                  multiple
                                                  options={[]}
                                                  value={selectedValues?.city || []}
                                                  onChange={(event, value) =>
                                                    handleAutocompleteChange(
                                                      event,
                                                      value,
                                                      "city",
                                                      10,
                                                      5
                                                    )
                                                  }
                                                  inputValue={inputValue.city}
                                                  onInputChange={(event, newValue) =>
                                                    handleInputChange(event, newValue, "city")
                                                  }
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                      id="outlined-basic"
                                                      label="Cities"
                                                      variant="outlined"
                                                      style={{
                                                        width: "100%",
                                                        marginBottom: "20px",
                                                      }}
                                                      onChange={(event) =>
                                                        setCurrentInput(event.target.value)
                                                      }
                                                    />
                                                  )}
                                                  style={{ width: isSmallScreen ? "91%" : "100%" }}
                                                />
                                                {errors.city && (
                                                  <Typography variant="body2" color="error">
                                                    {errors.city}
                                                  </Typography>
                                                )}
                                              </>
                                            ) : (
                                              <MDTypography
                                                display="inline"
                                                variant="body2"
                                                verticalAlign="middle"
                                              >
                                                {user?.favCity &&
                                                  user.favCity.slice(0, 10).map((city, index) => (
                                                    <Chip
                                                      key={index}
                                                      label={city}
                                                      variant="outlined"
                                                      style={{
                                                        marginRight: "5px",
                                                        marginBottom: "5px",
                                                        cursor: "pointer",
                                                        fontSize: "16px",
                                                        fontWeight: "bolder",
                                                      }}
                                                    />
                                                  ))}
                                              </MDTypography>
                                            )}
                                            {isCityEditMode && (
                                              <>
                                                <MDBox mt={2} mb={2}>
                                                  <MDButton
                                                    component="a"
                                                    style={{
                                                      backgroundColor: "transparent",
                                                      color: "#0d6efd",
                                                      textDecoration: "underline",
                                                      textTransform: "none",
                                                      fontSize: "16px",
                                                    }}
                                                    onClick={() => handleButtonClick("city")}
                                                  >
                                                    Add
                                                  </MDButton>
                                                  <MDButton
                                                    style={{
                                                      backgroundColor: "#0d6efd",
                                                      color: "white",
                                                    }}
                                                    variant="gradient"
                                                    onClick={handleSaveClick}
                                                    disabled={
                                                      selectedValues.city.length < 5 ||
                                                      selectedValues.city.length > 10
                                                    }
                                                  >
                                                    Update
                                                  </MDButton>
                                                  <MDButton
                                                    component="a"
                                                    style={{
                                                      backgroundColor: "transparent",
                                                      color: "#0d6efd",
                                                      textDecoration: "underline",
                                                      textTransform: "none",
                                                      fontSize: "16px",
                                                    }}
                                                    onClick={handleClose}
                                                  >
                                                    Cancel
                                                  </MDButton>
                                                </MDBox>
                                              </>
                                            )}
                                          </MDBox>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </Box>
                                </MDBox>
                              </MDBox>
                            </Grid>
                          </Grid>

                          <Grid container spacing={1}>
                            <Grid item xs={12} md={12} xl={12}>
                              <MDBox>
                                <MDBox
                                  component="fieldset"
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: "5px",
                                    border: "none",
                                  }}
                                >
                                  <Box component="fieldset" className="filedset_border">
                                    <legend
                                      style={{
                                        marginLeft: "15px",
                                        fontSize: "1.5rem",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Interests and Hobbies{" "}
                                    </legend>
                                    <Box>
                                      <Icon
                                        variant="text"
                                        color="info"
                                        onClick={() => setEditedHobbies(!isHobbiesEditMode)}
                                        sx={{
                                          cursor:
                                            isHobbiesEditMode === undefined ? "pointer" : "default",
                                          float: "right",
                                          marginRight: "20px",
                                          marginTop: "-1%",
                                        }}
                                      >
                                        {isHobbiesEditMode
                                          ? ""
                                          : isHobbiesEditMode !== undefined && (
                                              <EditIcon
                                                sx={{
                                                  cursor: "pointer",
                                                  fontSize: "16px !important",
                                                }}
                                              />
                                            )}
                                      </Icon>
                                      <Grid container spacing={1} style={{ marginLeft: "10px" }}>
                                        <Grid item xs={12} md={11} xl={11}>
                                          <MDBox mb={2}>
                                            {isHobbiesEditMode ? (
                                              <>
                                                <Hobbies
                                                  user={user}
                                                  closeEditMode={() => setEditedHobbies(false)}
                                                  setUser={setUser}
                                                />
                                              </>
                                            ) : (
                                              <Typography
                                                display="inline"
                                                variant="body2"
                                                verticalAlign="middle"
                                              >
                                                {user?.hobbies && (
                                                  <div>
                                                    {user.hobbies
                                                      .slice(0, 10)
                                                      .map((hobby, index) => (
                                                        <Chip
                                                          key={index}
                                                          label={hobby}
                                                          style={{
                                                            marginRight: "5px",
                                                            marginBottom: "5px",
                                                            cursor: "pointer",
                                                            fontSize: "16px",
                                                            fontWeight: "bolder",
                                                          }}
                                                          component="a"
                                                          variant="outlined"
                                                        />
                                                      ))}
                                                  </div>
                                                )}
                                              </Typography>
                                            )}
                                          </MDBox>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </Box>
                                </MDBox>
                              </MDBox>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
        </Container>
      </section>
      <ResetUserPassword isVisible={showResetModal} addCompany={ResetPassword} Close={closeModal} />
      <QuestionModal isvisibale={showQuestions} hanldeComplete={userProfile} />

      <Footer />
    </>
  );
};

export default UserProfile;
