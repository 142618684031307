import { useEffect } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Swal from "sweetalert2/dist/sweetalert2";
import Header from "MainComponet/Header";
import Footer from "MainComponet/Footer";
import 'MainComponet/css/custom.css'
function ForgetPassword() {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (values) => {
      localStorage.clear();
      axios
        .post(`${process.env.REACT_APP_API_PATH}/sendpasswordlink`, values)
        .then((res) => {
          if (res.data.status == 201) {
            Swal.fire("Done!", "Email sent Successfully", "success");

            navigate("/login");
          } else {
            toast.success("Email sent Successfully", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    validate: (values) => {
      const errors = {};
      if (!values.email) errors.email = "Email is required";
      else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email))
        errors.email = "Invalid email address";

      return errors;
    },
  });

  useEffect(() => {
    const auth = localStorage.getItem("token");
    if (auth) {
      navigate("/companies");
    }
  });
  useEffect(() => {
    AOS.init({ duration: 500, delay: 50 });

    }, []);
  return (
    <>
      {" "}
      <Header/>
      
      <div className="border1"  data-aos="fade-right"
              >
                    <div className=" container  mt-5">
<form onSubmit={formik.handleSubmit} >
      <section class=" gradient-custom">
  <div class="container">
    <div class="row d-flex justify-content-center align-items-center">
            <h2 className="text-center mt-4"><span>  Forgot </span> Password</h2>
      <div class="col-12 col-md-8 col-lg-6 col-xl-5 py-5">
        <div class="card bg-white text-black" style={{ borderRadius: "1rem",boxShadow: '#0d6efd73 0px 2px 4px', }}>
          <div class="card-body p-5">
            <div class="mb-md-5">
              <div class="mb-3">
                <label for="email" class="form-label">Email address</label>
                <input type="email" className="form-control" id="email" required name="email" value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}/>
                           <div style={{ color: "red", fontSize: "14px" }}>
                    {formik.touched.email && formik.errors.email ? (
                      <div color="red">{formik.errors.email}</div>
                    ) : null}
                  </div>
              </div>
             


             
<div className=" text-center">

<button className="btn btn-primary d-block w-100" type="submit" >
Submit
                    </button>
                    <ToastContainer limit={1} />
</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
</form>
</div>
</div>
<div className="border1" data-aos="fade-right">
  <Footer/>
  </div>
    </>
  );
}

export default ForgetPassword;
