import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import "./styles.css";
import { Card, Container, } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import MDBox from "components/MDBox";
import welcome from "assets/images/shutterstock_379676116.jpg";
import QusHeader from "./QusHeader";
const Frontpage = () => {
  const [state, setState] = useState();
  const [user, setUser] = useState();
  const navigate = useNavigate();

  const changeValue = (e) => {
    if (e.key === "Enter") {
      setState(e.target.value);
    }
  };

  useEffect(() => {
    window.addEventListener("keypress", (e) => {
      if (e.key === "Enter") {
        setState(e.target.value);
        navigate("/questionere");
      }
    });
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");

    axios
      .post(`${process.env.REACT_APP_API_PATH}/validuser`, { token })
      .then((res) => {
        if (res.data.status == 200) {
          setUser(res.data.data);
        }
      })
      .catch((err) => console.log(err));

    if (user?.iscomplete) {
      navigate("/userprofile");
    }
    if (user?.isTestStart) {
      navigate("/questionere");
    }
  }, [user?.iscomplete, user?.isTestStart]);

  const handelNext = () => {
    navigate("/questionere");
  };

  return (
    <>
      <QusHeader showHeading={false} />
      {user && (
        <Box
          sx={{
            my: 8,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid
            container
            spacing={1}
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              minHeight: "81vh",
            }}
            xs={12}
            sm={12}
            md={12}
          >
            {/* <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "17vh",
              }}
            ></Box> */}
            <Container className="min-h-screen bg-gray-100 text-gray-900 flex justify-center">
              <Card
                className="max-w-screen-xl m-0 sm:m-10 bg-white shadow sm:rounded-lg flex justify-center flex-1"
                sx={{ borderRadius: "10px" }}
              >
                <Grid container spacing={0}>
                  <Grid item xs={12} lg={12} xl={12}>
                    <Grid container item>
                      <Grid item xs={12} lg={12} xl={6}>
                        {" "}
                        <img
                          src={welcome}
                          alt="Your Alt Text"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            visibility: "visible",
                            borderTopLeftRadius: "10px",
                            borderBottomLeftRadius: "10px",
                          }}
                        />
                      </Grid>
                      <Grid xs={12} lg={12} xl={6}>
                        <MDBox pt={4} pb={3} px={3}>
                          <MDBox
                            mt={-3}
                            p={6}
                            textAlign="center"
                            sx={{
                              marginTop: "10px",
                              marginLeft: "auto",
                              marginRight: "auto",
                              display: "block",
                              fontSize: "20px",
                            }}
                          >
                            Welcome to The Connectar ! You are about to be guided through a sequence
                            of questions that will help us get to know you better. There are no
                            right or wrong answers , please just be yourself and respond as honestly
                            as possible. The questionnaire typically takes around 10 minutes to
                            complete, when you're ready. Click on the start button below
                            <MDBox mt={4} mb={1} textAlign="center">
                            <div className="text-center">
  <button
    onClick={handelNext}
    className="btn btn-primary d-block mx-auto w-50"
    style={{
      marginTop: "10px",
      "&:hover": {
        backgroundColor: "#1A73E8",
        color: "black !important",
      },
    }}
  >
    Click to Start
  </button>
</div>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Container>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default Frontpage;
