import React, { useState } from "react";
import Box from "@mui/material/Box";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "assets/images/small-logos/Logo-removebg-preview.png";
import doLogout from "../helper.js";
import 'MainComponet/css/custom.css'
import  Swal  from "sweetalert2";
const QusHeader = ({ data, showHeading }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const handleButtonClick = () => {
    setAnchorEl(null);
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0d6efd",
      cancelButtonColor: "#d33",
      confirmButtonText: "Logout!",
    }).then(async(result) => {
      if (result.isConfirmed) {
        const response = await doLogout();
        if(response.data.status === 200 ){
          localStorage.removeItem('token');
          navigate('/')
        }
      }
    });
  };

  return (
  <>

        <section className="circle circle1"></section>
      <section className="circle circle2"></section>
      <header id="header">
        <nav className="navbar navbar-expand-lg fixed-top navbar-light" style={{backgroundColor: '#ffffff', boxShadow: '0 2px 4px rgba(0,0,0,0.1)'}}>
          <div className="container" data-aos="fade-down">
            <NavLink to="/" className="navbar-brand" >
              <img src={Logo} alt="The Connectar" height="300px" width="300px" />
            </NavLink>
            <Box
            sx={{
              flexGrow: { xs: 1, md: 1, lg: 2, xl: 30 }, 
              display: { xs: "flex", md: "flex", lg:"flex" },
              justifyContent: "center",
            }}
          ></Box>
            {!showHeading && (
              <>
              
               <div className="d-flex flex-grow-justify-content-end"></div>
                          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle  navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
       
              <div className="d-flex" role="search">
                <button  className="btn btn-outline-primary me-3"  onClick={handleButtonClick}  >Logout</button >
              </div>
            </div>
            </>
            )}
          </div>
        </nav>
      </header>
  </>
  );
};

export default QusHeader;